import Utils from '../../utils/utils';
var qs = require('querystring');



export default async function sendInfoData(sito, user, session, key, value) {
    return new Promise(function (resolve) {


        fetch('https://www.sbadabum.com/sbadabum/sendInfoData.php', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              //'Content-Type': 'application/json'
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify({
                'sito': sito,
                'user': user,
                'session': session,
                'key': key,
                'value': Utils.escapeString(value),
            }) 
          })
        .then(response => {
          return JSON.stringify(response);
        })
        .then(result => resolve(result))
        });

}
