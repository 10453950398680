import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import MainBanner from "../components/ITStartup/MainBanner";
import Features from "../components/ITStartup/Features";
import ServicesArea from "../components/ITStartup/ServicesArea";
import RecentWorks from "../components/Common/RecentWorks";
import Footer from "../components/_App/Footer";
import { logPage } from '../analytics/analytics';
import { MessengerChat } from 'react-messenger-chat-plugin';
import '../assets/css/bootstrap.min.css';
import GoogleManager from "../utils/GoogleManager";
import AboutUs from "../components/ITStartup/AboutUs";

const IndexPage = () => { 
    const aboutRef = React.useRef(null);

    useEffect(() => {
        setTimeout(() => { 
            const ref = window.location.href.includes("?ref=") ? window.location.href.split("?ref=")[1] : "";
            if(ref && aboutRef) {  
                aboutRef.current.scrollIntoView({ behavior: 'smooth' });  
            }
        }, 1000);
    }, []);

    logPage('HOME');

    return (
    <Layout>
        <GoogleManager />
        <SEO title="APPIUS - Software House & Web Agency" />
        <Navbar aboutRef={aboutRef} />
        <MainBanner />
        <AboutUs aboutRef={aboutRef} />
        <Features />
        <ServicesArea />
        <RecentWorks />
        <Footer />
        <MessengerChat pageId="100118916110273" language={"it_IT"} />
    </Layout>
    )
    }


export default IndexPage;
