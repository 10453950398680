var qs = require('querystring');

export default async function sendLandingEvent(event_key, landing_id, cookie, event_payload, event_weight) {
  	return new Promise(function (resolve) {
		fetch('https://www.appius.it/appius_apis/logEvent.php', {
			method: 'POST',
			mode: 'cors', 
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: qs.stringify({
				'event_key': event_key,
				'landing_id': landing_id,
				'landing_cookie': cookie,
				'event_payload': event_payload,
				'event_weight': event_weight,
			})
	  	}).then( async response => {
			return await response.json();
		}).then(result => resolve(result))
	}
	);
}
