var qs = require('querystring');

export default async function sendEventData(sito, user, session, prog, event) {
    return new Promise(function (resolve) {

        fetch('https://www.sbadabum.com/sbadabum/sendEventData.php', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              //'Content-Type': 'application/json'
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify({
                'sito': sito,
                'user': user,
                'session': session,
                'prog': prog,
                'event': event
                }) 
          })
        .then(response => {
          return JSON.stringify(response);
        })
        .then(result => resolve(result))
        });
}
