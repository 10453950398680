import Cookies from 'universal-cookie';
import sendEventData from '../services/analytics/sendEventData';
import sendInfoData from '../services/analytics/sendInfoData';
import sendPageData from '../services/analytics/sendPageData';
import $ from 'jquery';
import GA4React from 'ga-4-react';
import sendLandingEvent from '../services/sendLandingEvent';


function resetPageScroll(){
    window.middleReached = false;
    window.bottomReached = false;
    
}

function getDocHeight() {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
}

var isBrowser = typeof window !== `undefined`;
console.log('isBrowser = ' + isBrowser);

isBrowser && $(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() == getDocHeight()) {
        if (window.bottomReached == false){
            window.bottomReached = true;
            logEvent('bottom reached');
        }
    }
    if($(window).scrollTop() + $(window).height() > getDocHeight() / 2) {
        if (window.middleReached == false){
            window.middleReached = true;
            logEvent('middle reached');
        }
    }
});

function generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
  }


function getUser(){
    const cookies = new Cookies();
    var user = cookies.get('user', { path: '/' });
    if (user == undefined || user == ''){
        user = generateUuid();
        cookies.set('user', user, { path: '/' });
    }
    return user;
}

function getProgress(){
    const cookies = new Cookies();
    var progress = 1;
    var progressStr = cookies.get('progress', { path: '/' });
    if (progressStr == undefined || progressStr == ''){
        progress = 1;
    }
    else {
        progress = parseInt(progressStr) + 1;
    }

    cookies.set('progress', progress.toString(), { path: '/' });

    return progress;
}

function getSession() {

    var sessionExpired = false;
    const cookies = new Cookies();
    var session = cookies.get('session', { path: '/' });
    var timeNow = Date.now();

    var lastUpdateSession = cookies.get('sessionLastUpdate', { path: '/' });
    if (lastUpdateSession == undefined || lastUpdateSession == '') lastUpdateSession = "0";
    var lastUpdate = 0;
    try {
        lastUpdate = parseInt(lastUpdateSession);
    } catch (err){
        lastUpdate = 0;
    }

    if (timeNow - lastUpdate > 10 * 60 * 1000)
        sessionExpired = true;

    if (session == undefined || session == '' || sessionExpired == true){
        session = generateUuid();
        cookies.set('session', session, { path: '/' });
        cookies.set('sessionLastUpdate', timeNow.toString(), { path: '/' });

        var isBrowser = typeof window !== `undefined`;
        isBrowser && logEvent('infoOS ' + window.navigator.appVersion);

    }
    return session;
}

function updateSession(){
    const cookies = new Cookies();
    var timeNow = Date.now();
    cookies.set('sessionLastUpdate', timeNow.toString(), { path: '/' });
}


async function logPage(page){

    var session = getSession();
    var user = getUser();
    var progress = getProgress();

    updateSession();
    await sendPageData('appius', user, session, progress, page);

}

async function logEventLanding(event, id, cookie, weight){
    var session = getSession();
    var user = getUser();
    var progress = getProgress();
    
    await sendLandingEvent(event, id, cookie, JSON.stringify({user, session, progress}), weight);

}   

async function logEvent(event){

    var session = getSession();
    var user = getUser();
    var progress = getProgress();
    
    updateSession();
    await sendEventData('appius', user, session, progress, event)

    const analitycs = new GA4React("G-FNZPW9WMY7").initialize();
    analitycs.then(ga => {
        ga.event(event, "event", JSON.stringify({user, session, progress, event}));
    }).catch(e => {
        console.error(e);
    })

}

async function logInfo(key, value){

    var session = getSession();
    var user = getUser();
    
    updateSession();
    await sendInfoData('appius', user, session, key, value)

}



export {logPage, logEvent, logInfo, resetPageScroll, logEventLanding}
